<template>
  <v-container>

    <v-row>
      <v-col cols="12">

        <v-row class="mb-3">
          <v-col>
            <v-btn to="/admin" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title class="pb-0">

            <v-container>

              <v-row>
                <v-col class="mb-2 py-0">
                  <p class="text-h5 mb-0">
                    <v-avatar color="orange" size="38"><v-icon dark>mdi-draw</v-icon></v-avatar>
                    Proceso de inscripción de talleres
                  </p>
                </v-col>
              </v-row>

            </v-container>
            
          </v-card-title>

          <v-card-text>
            <v-container>

              <!-- Activate/Deactivate Reg. process -->
              <v-row>
                <v-col v-if="!isLoadingRegProcess">

                  <v-alert dense :type="(inRegistrationProcess) ? 'success' : 'warning'" border="left" text>
                    <v-row align="center">
                      <v-col class="grow">
                        La funcionalidad de inscripción de talleres está {{(inRegistrationProcess) ? 'activada' : 'desactivada'}}
                      </v-col>
                      <v-col class="shrink">
                        <v-btn @click="inToggleDialog = true" small>{{(inRegistrationProcess) ? 'Desactivar' : 'Activar'}}</v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>

                </v-col>
                <v-col v-else>
                  <LoadingIndicator />
                </v-col>
              </v-row>

              <v-divider class="my-3"></v-divider>

              
              <v-row v-if="!isLoading">
                <v-col>
                  
                  <!-- Last registration info -->
                  <v-card class="px-3 pb-3">
                    <v-card-title>
                      <p class="mb-0 mr-2">Último proceso de inscripción</p>
                      <v-chip :color="(isInscriptionPublished) ? 'success' : 'secondary'">
                        {{(isInscriptionPublished) ? 'Visible' : 'No visible'}}
                      </v-chip>
                    </v-card-title>

                    <v-card-text>
                      <p>
                        <v-icon class="mr-2">mdi-pen</v-icon>
                        <strong>Nombre:</strong> {{registration.name}}
                      </p>

                      <p>
                        <v-icon class="mr-2">mdi-calendar</v-icon>
                        <strong>Fecha de Inicio:</strong> {{registration.startDate}}
                      </p>

                      <p>
                        <v-icon class="mr-2">mdi-calendar</v-icon>
                        <strong>Fecha de Término:</strong> {{registration.endDate}}
                      </p>

                      <p>
                        <v-icon class="mr-2">mdi-human-male-board</v-icon>
                        <strong># Talleres:</strong> {{registration.courses.length}}
                      </p>

                      <p>
                        <v-icon class="mr-2">mdi-school</v-icon>
                        <strong># Inscripciones:</strong> {{registration.totalRegs}}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!-- Options -->
                <v-col class="px-5">
                  <p class="text-h6">Opciones</p>
                  
                  <v-btn @click="inQRdialog = true" color="primary" block class="mb-3" small>Ver Link y QR del proceso de inscripción</v-btn>
                  <v-btn to="/admin/viewlastregistrations" color="primary" block class="mb-3" small>Ver inscripciones del último proceso</v-btn>
                  <v-btn to="/admin/registrations" color="primary" block class="mb-3" small>Gestionar los procesos de inscripción</v-btn>
                </v-col>

              </v-row>
              <v-row v-else>
                <v-col>
                  <LoadingIndicator />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-card>

      </v-col>
    </v-row>

    <!-- Dialogs -->
    <ToggleRegistrationProcessDialog v-model="inToggleDialog" :inRegistrationProcess="inRegistrationProcess" @toggled="loadInRegistration()" />
    <RegistrationQRLinkDialog v-model="inQRdialog" />

  </v-container>
</template>

<script>
import ToggleRegistrationProcessDialog from '@/components/admin/ToggleRegistrationProcessDialog.vue'
import RegistrationQRLinkDialog from '@/components/admin/RegistrationQRLinkDialog.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import { getDDMMYYYY } from '@/libs/dateHelpers'

export default {
  name: `AdminRegistrationStatus`,
  components: {
    ToggleRegistrationProcessDialog,
    RegistrationQRLinkDialog,
    LoadingIndicator,
  },
  computed: {
    isInscriptionPublished() {

      const today = new Date()
      const betweenDates =  (today >= this.registration.rawStartDate && today <= this.registration.rawEndDate)
      
      return this.inRegistrationProcess && betweenDates
    },
  },
  data: () => ({
    //state
    search: '',
    isLoading: true,
    isLoadingRegProcess: true,
    inToggleDialog: false,
    inQRdialog: false,
    //data
    inRegistrationProcess: false,
    registration: {
      _id: null,
      name: null,
      startDate: null,
      endDate: null,
      rawStartDate: null,
      rawEndDate: null,
      courses: [],
      totalRegs: 0,
    },
  }),
  methods: {
    async loadLastRegistration() {
      try {
      
        this.isLoading = true
      
        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/registration`)

        if(!resp.data.data) {
          this.isLoading = false
          return
        }

        this.registration = {
          name: resp.data.data.name,
          startDate: getDDMMYYYY(new Date(resp.data.data.startDate.replace('Z', ''))),
          endDate: getDDMMYYYY(new Date(resp.data.data.endDate.replace('Z', ''))),
          rawStartDate: new Date(resp.data.data.startDate.replace('Z', '')),
          rawEndDate: new Date(resp.data.data.endDate.replace('Z', '')),
          courses: resp.data.data.courses,
          totalRegs: resp.data.data.courses.reduce((acc, course) => acc + course.students.length, 0),
        }
      
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async loadInRegistration() {
      try {

        this.isLoadingRegProcess = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/settings`)
        this.inRegistrationProcess = response.data.data.inRegistrationProcess || false

      } catch (e) {
        console.log(e)
      } finally {
        this.isLoadingRegProcess = false
      }
    },
    openDeleteDialog(id) {
      this.deleteURL = `${process.env.VUE_APP_APIURL}/registration/id/${id}`
      this.deleteDialog = true
    },
  },
  mounted() {
    Promise.all([
      this.loadLastRegistration(),
      this.loadInRegistration(),
    ])
  },
}
</script>