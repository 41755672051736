<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="400px" >

      <v-card>

        <v-card-title class="text-body-1 text-wrap">Está seguro de que quiere {{(inRegistrationProcess) ? 'desactivar' : 'activar'}} el proceso de inscripción de talleres?</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn @click="toggleRegistrationProcess" :color="(inRegistrationProcess) ? 'warning' : 'success'" dark :loading="isSaving">
            {{(inRegistrationProcess) ? 'Desactivar' : 'Activar'}}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
export default {
  name: 'ToggleRegistrationProcessDialog',
  props: {
    value: Boolean,
    inRegistrationProcess: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
    }
  },
  methods: {
    async toggleRegistrationProcess() {
      try {
        
        this.isSaving = true

        if(this.inRegistrationProcess) {
          await this.axios.put(`${process.env.VUE_APP_APIURL}/settings`, {
            inRegistrationProcess: false
          })
        } else {
          await this.axios.put(`${process.env.VUE_APP_APIURL}/settings`, {
            inRegistrationProcess: true
          })
        }

        this.isSaving = false
        this.show = false

        this.snackcolor = 'success'
        this.snacktext = `El proceso ha sido ${!this.inRegistrationProcess ? 'activado' : 'desactivado'}`
        this.isSnackbar = true

        this.$emit('toggled')

      } catch (e) {

        console.error(e)
        this.snackcolor = 'error'
        this.snacktext = 'Ha ocurrido un error al actualizar el estado del proceso'
        this.isSnackbar = true

      } finally {
        this.isSaving = false
      }
    },
  }
}
</script>