<template>
  <v-dialog transition="dialog-top-transition" v-model="show" max-width="600px" >

    <v-card>
      <v-card-title class="blue darken-2 white--text mb-4">
        <span>Link y QR de la Inscripción</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <img
                src="@/assets/qrgalpon.svg"
                style="max-width:400px;width:100%"
              />
            </v-col>
            <v-col cols="6">
              <p>
                <strong>Link:</strong>
                <a href="https://galpon-cultural.ucn.cl/#/inscripcion">
                  https://galpon-cultural.ucn.cl/#/inscripcion
                </a>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="show = false" >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>

export default {
  name: "RegistrationQRLinkDialog",
  props:{
    value: Boolean,
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  methods: {
    closeDialog() { this.show = false }
  }
}
</script>